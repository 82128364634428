export const chunk = (array: any[], chunkSize: number) => {
  const chunkedArray = [];

  let index = 0;

  while (index < array.length) {
    chunkedArray.push(array.slice(index, chunkSize + index));

    index += chunkSize;
  }

  return chunkedArray;
};

export const sum = (array: number[]) =>
  array.reduce(
    (accumulator: number, currentValue: number) => accumulator + currentValue
  );
