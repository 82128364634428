import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import Gallery from "../components/gallery";
import SEO from "../components/seo";
import { ContentfulWork, ContentfulCategory } from "../../graphql-types";
import { FluidObject } from "gatsby-image";

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export const pageQuery = graphql`
  query GalleryQuery {
    categories: allContentfulCategory {
      nodes {
        title
        id
        slug
      }
    }

    years: allContentfulWork {
      distinct(field: year)
    }

    work: allContentfulWork {
      nodes {
        title
        slug
        id
        images {
          fluid(maxWidth: 800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;

type Props = {
  data: {
    work: { nodes: ContentfulWork[] };
    years: { distinct: number[] };
    categories: { nodes: ContentfulCategory[] };
  };
};

const IndexPage = ({ data: { categories, work, years } }: Props) => {
  return (
    <Layout>
      <SEO title="Home" />
      <ul>
        {categories.nodes.map(({ title, slug }) => (
          <li>
            <Link to={`/categories/${slug}`}>{title}</Link>
          </li>
        ))}
      </ul>
      <select>
        <option>Alles</option>
        {years.distinct.map((year) => (
          <option>{year}</option>
        ))}
        <option>Onbekend</option>
      </select>
      <Gallery
        images={work.nodes
          .map(({ images, slug, id }) => {
            if (
              images === null ||
              images === undefined ||
              !images[0]?.fluid ||
              !slug
            ) {
              return null;
            }

            const fluid = images[0].fluid as FluidObject;

            return { fluid, slug, id };
          })
          .filter(notEmpty)}
        itemsPerRow={[1, 2, 2, 3, 5]}
      />
    </Layout>
  );
};

export default IndexPage;
