import Img, { FluidObject } from "gatsby-image";
import React from "react";
import { chunk, sum } from "../utils/array";
import { Box } from "rebass";
import { Link } from "gatsby";
import { ContentfulFluid } from "../../graphql-types";

type ImageWithSlug = {
  id: string;
  fluid: FluidObject;
  slug: string;
};

type Props = {
  images: ImageWithSlug[];
  itemsPerRow: number[];
};

const Gallery = ({
  images,
  itemsPerRow: itemsPerRowByBreakpoints = [1],
}: Props) => {
  const aspectRatios = images.map(({ fluid }) => fluid.aspectRatio);

  const rowAspectRatioSumsByBreakpoints = itemsPerRowByBreakpoints.map(
    (itemsPerRow) =>
      chunk(aspectRatios, itemsPerRow).map((rowAspectRatios) =>
        sum(rowAspectRatios)
      )
  );

  return (
    <Box>
      {images.map(({ id, fluid, slug }, index) => {
        return (
          <Box
            key={id}
            width={rowAspectRatioSumsByBreakpoints.map(
              (rowAspectRatioSums, widthIndex) => {
                const rowIndex = Math.floor(
                  index / itemsPerRowByBreakpoints[widthIndex]
                );
                const rowAspectRatioSum = rowAspectRatioSums[rowIndex];

                return `calc(${
                  (fluid.aspectRatio / rowAspectRatioSum) * 100
                }% - 20px)`;
              }
            )}
            css={`
              display: inline-block;
              vertical-align: middle;
              transition: filter 0.3s;
              margin: 10px;
              :hover {
                filter: brightness(87.5%);
              }
            `}
          >
            <Link to={`work/${slug}`}>
              <Img fluid={fluid} />
            </Link>
          </Box>
        );
      })}
    </Box>
  );
};

export default Gallery;
